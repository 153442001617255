/**
 * Configuration for the Neue app.
 * @ngInject
 */
function Config($httpProvider, $sceDelegateProvider) {
  _httpConfig($httpProvider);
  _sceConfig($sceDelegateProvider);
}

/**
 * Sets up gobal HTTP service configuration.
 * @param {angular.$httpProvider} $httpProvider
 */
function _httpConfig($httpProvider) {
  var tokenEl = document.querySelector('meta[name=csrf-token]');
  if (tokenEl) {
    $httpProvider.defaults.headers.common['X-CSRF-Token'] =
      tokenEl.getAttribute('content');
  }

  $httpProvider.defaults.headers.common.Accept = 'application/json';
}

/**
 * Sets up a trusted resource Url whitelist.
 * https://code.angularjs.org/1.4.3/docs/api/ng/provider/$sceDelegateProvider
 * @param {angular.$sceDelegateProvider} $sceDelegateProvider
 */
function _sceConfig($sceDelegateProvider) {
  $sceDelegateProvider.resourceUrlWhitelist([
    // Allow same origin resource loads.
    'self',
    // Allow loading from our assets CDN domain.
    'http://afwebcdn*.fonts.adobe.com/**',
    'https://afwebcdn*.fonts.adobe.com/**'
  ]);
}

module.exports = Config;
