import { getBookmarks } from '@adobe-fonts/site/lib/Libraries.js';
import imsEvents from './ims_events.js';

export const getLibraryElementData = (element) => {
  const scriptData = element.querySelector('[data-name=library-elements]');
  if (scriptData) {
    return JSON.parse(scriptData.innerText);
  }

  const libraryElementsAttribute = element.getAttribute('data-library-elements');
  if (libraryElementsAttribute) {
    return JSON.parse(libraryElementsAttribute);
  }

  return [];
};

export const toLibraryElement = (font, textSamples) => {
  const paidFont = font.font.sync?.library_availability?.full && !font.font.sync?.library_availability?.trial;

  const libraryElement = {
    postScriptName: font.postscript_name,
    name: font.name,
    localizedName: font.localized_names,
    family: font.family.web_name,
    localizedFamily: font.localized_family_name,
    style: font.variation_name,
    localizedStyle: font.localized_subfamily_name,
    typekitFontId: font.font.sync?.font_id ?? font.font.web?.font_id,
    foundry: font.family.foundry.name,
    typographicFamily: font.family.typographic_family_name,
    webId: font.family.web_id,
    cssName: font.family.css_font_stack.split(',')[0].replaceAll('"', ''),
    webFontStyle: font.font.web.fvd[0] === 'i' ? 'italic' : 'normal',
    webFontWeight: font.font.web.weight,
    previewText: ((textSamples || {})[font.default_language] || {}).grid,
    paidFont: paidFont
  };
  if (font.designers.length > 0) {
    libraryElement.designer = new Intl.ListFormat().format(font.designers.map((designer) => designer.name));
  }
  return libraryElement;
};

/**
 * Checks if the public library is already bookmarked by the user
 * The Promises' returns are added to be compatible with Safari
 * @param {*} button
 * @returns
 */
export const checkBookmarks = (button) => {
  imsEvents.addOnReadyHandler(async () => {
    try {
      await window.adobeIMS.getProfile();
      const bookmarksResponse = await getBookmarks();
      if (!bookmarksResponse) {
        return;
      }
      const bookmarkIds = bookmarksResponse.bookmarks.map(bookmark => bookmark.id);
      if (bookmarkIds.includes(button.bookmarkId)) {
        button.followed = true;
        window.dispatchEvent(new CustomEvent('library-bookmarked'));
      }
    } catch (e) {
      console.error(e);
    }
  });
};

export default {
  getLibraryElementData,
  toLibraryElement
};
