/**
 * A class that returns embed codes for a project
 *
 * @constructor
 * @params {isDynamic} whether the project is dynamic or not
 * @param {projectEmbedDetails} the various embed details needed
 * @ngInject
 */
function EmbedCodeModel(isDynamic, projectEmbedDetails) {
  this._isDynamic = isDynamic;
  this._projectEmbedDetails = projectEmbedDetails;
}

EmbedCodeModel.prototype.getIsDynamic = function() {
  return this._isDynamic;
};

EmbedCodeModel.prototype.getProjectEmbedDetails = function() {
  return this._projectEmbedDetails;
};

module.exports = EmbedCodeModel;
