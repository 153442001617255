var angular = require('angular');
const { trapFocus, releaseFocus } = require('../../util/focus.js');

/**
 * DialogService can be used to display a template as a modal dialog. A dialog
 * can have it's own Angular controller, or it can receive data from the
 * controller that opened it (or both).
 *
 * == Creating a dialog template ==
 *
 * A dialog template must have one top level element that wraps the contents of the dialog.
 * Dialog templates can be loaded using Angular inline templates:
 *
 *  HTML
 *  <script type="text/ng-template" id="my-template.html">
 *    <div class="my-dialog">
 *      <h1>My Dialog</h1>
 *    </div>
 *  </script>
 *
 *  JavaScript
 *  DialogService.show('my-template.html');
 *
 * Or by having an endpoint that returns the template's HTML:
 *
 *  JavaScript
 *  DialogService.show('/path/to/my_template');
 *
 * == Passing data to a template ==
 *
 * DialogService.show returns a promise that will pass along the dialog's scope. Data can be
 * passed to the dialog using its scope:
 *
 *   HTML
 *
 *   <div class="my-dialog">
 *     <h1>{{title}}</h1>
 *     <p>{{content}}</p>
 *   </div>
 *
 *  JavaScript
 *
 *  DialogService.show('/path/to/my_template').then(function(dialog) {
 *    dialog.title = "My dialog's title";
 *    dialog.content = "My dialog's content";
 *  });
 *
 * The same will work for handler methods:
 *
 *   HTML
 *
 *   <div class="my-dialog">
 *     <button ng-click="handleYesClick()">
 *       Yes
 *     </button>
 *     <button ng-click="handleNoClick()">
 *       No
 *     </button>
 *   </div>
 *
 *  JavaScript
 *
 *  var self = this;
 *
 *  DialogService.show('/path/to/my_template').then(function(dialog) {
 *    dialog.handleYesClick = function() {
 *      self.clickedYes();
 *    };
 *
 *    dialog.handleNoClick = function() {
 *      self.clickedNo();
 *    };
 *  });
 *
 * == Dialogs with their own controllers ==
 *
 * A dialog can declare its own controller using ng-controller syntax:
 *
 *   HTML
 *
 *   <div class="my-dialog" ng-controller="MyDialogController as myDialog">
 *     {{myDialog.answer}}
 *     <button ng-click="myDialog.handleYesClick()">
 *       Yes
 *     </button>
 *     <button ng-click="myDialog.handleNoClick()">
 *       No
 *     </button>
 *   </div>
 *
 *   JavaScript
 *
 *   function MyDialogController() {
 *     this.answer = '?';
 *   }
 *
 *   MyDialogController.prototype.handleYesClick = function() {
 *     this.answer = 'Yes';
 *   };
 *
 *   MyDialogController.prototype.handleNoClick = function() {
 *    this.answer = 'No';
 *   }
 *
 * == Closing a dialog ==
 *
 * Every dialog's scope has a default close method that will close the dialog:
 *
 *   HTML
 *
 *   <div class="my-dialog">
 *     <button ng-click="close()">
 *       Close
 *     </button>
 *   </div>
 */

/**
  * Define the dialog options config object.
  *
  * @typedef {Object} Typekit.Dialog.Options
  * @property {Boolean} closeOnDrag If true, close the dialog on drag events.
  * @property {String} extraWrapperClass An optional extra class for the dialog wrapper.
  * @property {Boolean} skipBodyListeners If true, don't add body listeners.
  */

/**
 * An Angular service used to create modal dialog boxes.
 * @ngInject
 */
function DialogService($compile, $document, $q, $rootScope, $templateRequest, $timeout, $window, DataService) {
  const DIALOG_OPEN_BODY_CLASS = 'tk-dialog-open';
  const DIALOG_CONTENT_CLASS = 'tk-dialog-content';
  const DIALOG_WRAPPER_HTML = '<div class="tk-dialog"><div class="tk-dialog-scrim"></div></div>';
  const ESC_KEY_CODE = 27;

  let _currentDialogElement;
  let _currentDialogContentEl;
  let _currentDialogScope;
  const _body = $document.find('body');
  const self = this;

  return {
    close: close,
    show: show
  };

  /**
   * Destroys the currently opened dialog (if there is one).
   */
  function close() {
    releaseFocus();
    if (_currentDialogElement && !self.closeDisabled) {
      _currentDialogScope.$destroy();
      _currentDialogElement.remove();

      // Ensure the dialog is fully removed outside of the angular $document too
      const dialog = document.querySelector('.tk-dialog');
      if (dialog) {
        dialog.remove();
      }

      _body.removeClass(DIALOG_OPEN_BODY_CLASS);
      _destroyBodyListeners();
    }
  }

  /**
   * Display a modal dialog.
   *
   * See documentation above for usage.
   *
   * @param {String} id If this is a url and the template hasn't already been loaded on
   *   the page, Angular will attempt the load the template at the url.
   *
   * @param {Typekit.Dialog.Options} options
   * @return {Promise}
   */
  function show(id, options) {
    options = options || {};

    if (_currentDialogElement) {
      close();
    }

    trapFocus();

    return $q(function(resolve) {
      $templateRequest(id).then(function(template) {
        resolve(_init(template, options));
      });
    });
  }

  /**
   * Destroys listeners that were created on the body by this service.
   * @private
   */
  function _destroyBodyListeners() {
    _body.off('click', _handleBodyClick);
    _body.off('keyup', _handleBodyKeyup);
  }

  /**
   * Creates a root scope to use with the dialog.
   * @private
   * @return {angular}
   */
  function _getScope() {
    var rootScope = $rootScope.$new();

    // Attach a default close method that will be available
    // to any dialog.
    rootScope.close = close;
    return rootScope;
  }

  /**
   * Handles clicks that take place outside of the dialog content element.
   *
   * @param {Event}
   * @private
   */
  function _handleBodyClick(event) {
    if (!_isChildOfDialogContent(event.target)) {
      close();
    }
  }

  /**
   * Handles keyup events that take place outside of the dialog content element.
   *
   * @private
   * @param {Event} event
   */
  function _handleBodyKeyup(event) {
    if (event.keyCode == ESC_KEY_CODE &&
        !_isChildOfDialogContent(event.target)) {
      close();
    }
  }

  /**
   * Closes the dialog on drag enter.
   *
   * @private
   * @param {Event}
   */
  function _handleWindowDragEnter(event) {
    if (!_isChildOfDialogContent(event.target)) {
      close();
    }
  }

  /**
   * Takes the template's HTML and compiles it as an Angular template, then
   * wraps it with a tk-dialog element and appends the wrapped element to the
   * body.
   *
   * @private
   * @param {String} template
   * @param {Typekit.Dialog.Options} options
   * @return {ngController|ngScope}
   */
  function _init(template, options) {
    // Load the data from any script tags in the template before compiling it so
    // that any controllers in the dialog will have access to the data when
    // they're contructed.
    DataService.loadDataFromTemplate(angular.element(template));

    _currentDialogContentEl = $compile(template)(_getScope()).addClass(DIALOG_CONTENT_CLASS);
    _currentDialogElement = _wrapContent(_currentDialogContentEl, options);
    _currentDialogScope = _currentDialogContentEl.scope();
    _body.append(_currentDialogElement).addClass(DIALOG_OPEN_BODY_CLASS);

    if (options.closeOnDrag) {
      angular.element($window).on('dragenter', _handleWindowDragEnter);
    }

    if (!options.skipBodyListeners) {
      _initBodyListeners();
    }

    if (options.closeDisabled) {
      self.closeDisabled = true;
    }

    return _currentDialogContentEl.controller() || _currentDialogContentEl.scope();
  }

  /**
   * Initializes listeners on the body that will close the dialog if
   * the event doesn't take place inside the dialog content.
   * @private
   */
  function _initBodyListeners() {
    // Register these events after the dialog has already been displayed.
    $timeout(function() {
      _body.on('click', _handleBodyClick);
      _body.on('keyup', _handleBodyKeyup);
    }, 0);
  }

  /**
   * Is the element a child of the current dialog content element?
   *
   * @private
   * @param {Element} element
   * @return {Boolean}
   */
  function _isChildOfDialogContent(element) {
    if (element == _currentDialogContentEl[0]) {
      return true;
    }

    if (element.parentNode) {
      return _isChildOfDialogContent(element.parentNode);
    }

    return false;
  }

  /**
   * Wraps the dialog content with the tk-dialog element. This is the parent
   * element that is used to style all dialogs.
   *
   * @private
   * @param {jQuery.Element} content
   * @param {Typekit.Dialog.Options} options
   * @return {jQuery}
   */
  function _wrapContent(contentEl, options) {
    return angular.element(DIALOG_WRAPPER_HTML).
      addClass(options.extraWrapperClass).
      append(contentEl);
  }
}

module.exports = DialogService;
