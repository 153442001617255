var angular = require('angular');

var MAX_SEARCH_LIST_COUNT = 50;
var SLICE_COUNT = 100;
var SLICE_EACH_SCROLL = 10;

/**
 * A function that takes a Promise as input
 * and resolves it to handle the project-selector component
 *
 * @param {Promise} projectSelectorModelPromise
 * @ngInject
 */
function ProjectSelectorModel(projectSelectorModelPromise) {
  var self = this;

  self.scrollCount = 0;
  self.projectSelectorModelPromise = projectSelectorModelPromise;
  self.selectedProjectName = '';
  self.selectedProjectID = null;
  self.searchResults = [];
  self.showError = false;
  self.selectorDisabled = true;
  self.inputDisabled = true;
  self.dropdownVisible = false;
  projectSelectorModelPromise.then(function(response){
    self.projects = response.projects;
    self.isFamilyCJK = response.is_family_CJK;
    if (self.projects && self.projects.length > 0) {
      self.selectorDisabled = false;
    } else {
      self.inputDisabled = false;
    }
  });
}

ProjectSelectorModel.prototype.isDropdownVisible = function() {
  return this.dropdownVisible;
};

ProjectSelectorModel.prototype.getProjectSelectorModelPromise = function() {
  return this.projectSelectorModelPromise;
};

ProjectSelectorModel.prototype.isSelectorDisabled = function() {
  return this.selectorDisabled;
};

ProjectSelectorModel.prototype.isInputDisabled = function() {
  return this.inputDisabled;
};

ProjectSelectorModel.prototype.isProjectSelected = function() {
  return !(this.getSelectedProjectID() === null);
};

ProjectSelectorModel.prototype.resetProjectSelection = function() {
  self.selectedProjectName = '';
  self.selectedProjectID = null;
};

ProjectSelectorModel.prototype.getPlaceholder = function(firstProjectTranslation, alreadyExistingProjectTranslation) {
  if (this.selectorDisabled) {
    return firstProjectTranslation;
  } else {
    return alreadyExistingProjectTranslation;
  }
};

ProjectSelectorModel.prototype.getSelectedProjectID = function() {
  return this.selectedProjectID;
};

ProjectSelectorModel.prototype.getSearchResults = function() {
  return this.searchResults;
};

ProjectSelectorModel.prototype.getProjectName = function() {
  return this.projectName;
};

/**
 * Returns the project with the given project id.
 *
 * @param {Object} project
 * @returns {Object}
 */
ProjectSelectorModel.prototype.getExistingProject = function(project) {
  if (!project) {
    return;
  }

  return this.projects.filter(function(p) {
    return p.id === project.id;
  })[0];
};

/**
 * Returns the project, with the given project Name.
 * if the number of projects with the given name = 1.
 *
 * @param {String} projectName
 * @returns {Object}
 */
ProjectSelectorModel.prototype.getExistingProjectByProjectName = function(projectName) {
  var selectedProject;
  if (!this.projects) {
    return;
  }
  var projectsArr = this.projects.filter(function(p) {
    return p.name === projectName;
  });
  if (projectsArr.length == 1) {
    selectedProject = projectsArr[0];
  }

  return selectedProject;
};

/**
 * Handle the action when project name changes
 *
 * @param {String} projectName
 */
ProjectSelectorModel.prototype.onProjectNameChange = function(projectName) {
  this.showError = false;
  this.dropdownVisible = false;
  this.selectedProjectID = null;
  this.projectName = projectName;

  this.searchResults = [];
  if (this.projectName.length > MAX_SEARCH_LIST_COUNT) {
    this.projectName = this.projectName.substring(0, MAX_SEARCH_LIST_COUNT);
  }

  this.projects.forEach(function (project) {
    if (project.name.toUpperCase() === this.projectName.toUpperCase()) {
      this.showError = true;
    }
  }.bind(this));
};

/**
 * Handle the action when a project is selected
 *
 * @param {String} projectID
 * @param {String} projectName
 */
ProjectSelectorModel.prototype.onProjectSelection = function(projectID, projectName) {
  this.selectedProjectID = projectID;
  this.projectName = projectName;
};

ProjectSelectorModel.prototype.isCurrentProjectNameValid = function() {
  var currentProject = this.getExistingProjectByProjectName(this.projectName);
  return Boolean(this.projectName) && this.isProjectValid(currentProject) && !this.showError;
};

/**
 * Checks if a project with the given projectName is valid
 *
 * @param {String} project
 * @returns {Boolean}
 */
ProjectSelectorModel.prototype.isProjectValid = function(project) {
  var existingProject = this.getExistingProject(project);

  if (!existingProject) {
    return true;
  }

  if (this.isFamilyCJK && !existingProject.is_dynamic) {
    this.selectedProjectID = null;
    return false;
  }

  return true;
};

ProjectSelectorModel.prototype.isResultListAvailable = function() {
  return this.searchResults && this.searchResults.length > 0;
};

/**
 * Handles the selector button click
 */
ProjectSelectorModel.prototype.onSelectorButtonClick = function() {
  this.dropdownVisible = !this.dropdownVisible;
  if (this.isResultListAvailable() || !this.projects) {
    this.searchResults = [];
  } else {
    this.searchResults = this.getFormattedSearchResults(this.projects.slice(0, SLICE_COUNT));
  }
};

/**
 * @param [Array] projects
 * Takes as input array of projects
 * and for all the duplicate items adds ID to the project name,
 * that is displayed in the search results
 */
ProjectSelectorModel.prototype.getFormattedSearchResults = function(projects) {
  if (!projects || projects.length == 0) {
    return [];
  }

  var projectArr = angular.copy(projects);
  projectArr.forEach(function(project) {
    var similarProjects = projectArr.filter(function(p) {
      return p.name === project.name;
    });

    if (similarProjects.length == 1) {
      similarProjects.splice(similarProjects.indexOf(project), 1);
    }

    similarProjects.forEach(function (similarProject) {
      similarProject.name = similarProject.name + ' (' + similarProject.id + ')';
    });
  });
  return projectArr;
};

/**
 * Handles the actions when a project is selected
 *
 * @param {Object} project
 */
ProjectSelectorModel.prototype.onSelectProjectClick = function(project) {
  if (!this.isProjectValid(project)) {
    return;
  }
  this.searchResults = [];
  this.selectedProjectID = project.id;
  this.projectName = project.name;
  this.dropdownVisible = false;
  this.inputDisabled = true;
};

/**
 * Enables the input box for the user to type in
 */
ProjectSelectorModel.prototype.enableInputBox = function() {
  this.inputDisabled = false;
};

/**
 * Checks if the Project Selector text is invalid
 * @returns {boolean}
 */
ProjectSelectorModel.prototype.isInvalid = function() {
  return this.showError;
};

/**
 * Adds SLICE_EACH_SCROLL number of projects to the search results.
 */
ProjectSelectorModel.prototype.onNextScroll = function() {
  this.searchResults.push.apply(this.searchResults, this.getFormattedSearchResults(this.projects.slice(SLICE_COUNT + (this.scrollCount) * SLICE_EACH_SCROLL, SLICE_COUNT + (this.scrollCount + 1) * SLICE_EACH_SCROLL)));
  this.scrollCount = this.scrollCount + 1;
};

module.exports = ProjectSelectorModel;
