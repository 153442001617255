var _ = require('underscore');
var angular = require('angular');

var MAX_NUMBER_OF_MESSAGES = 3;
var TIMEOUT_MS = 4000;
var TIMEOUT_WITH_LINK_MS = 5000;
var TYPES = {
  ERROR: 'error',
  NEUTRAL: 'neutral',
  NOTICE: 'info',
  SUCCESS: 'success',
  FEEDBACK: 'feedback',
  FONT_GAME_BANNER: 'font_game_banner',
  RETIRED_FAMILY_BANNER: 'retired_family_banner',
  RETIRED_FOUNDRY_BANNER: 'retired_foundry_banner',
  SITE_MAINTENANCE_BANNER: 'site_maintenance_banner',
  WARN: 'warn'
};

/**
 * Angular factory that returns an instance of the notification service.
 * @ngInject
 */
function NotificationServiceFactory($timeout, $sce) {
  var notificationService = new NotificationService($timeout, $sce);
  notificationService.TYPES = TYPES;
  return notificationService;
}

module.exports = NotificationServiceFactory;

/**
 * A wrapper class for the notification service. Angular will create an instance of the
 * class as a singleton.
 */
function NotificationService($timeout, $sce) {
  this._messages = [];
  this._nextNotificationId = 0;

  this.$timeout = $timeout;
  this.$sce = $sce;
}

/**
 * Adds an error message to the service.
 * @param {String} text
 * @param {?Object} options
 */
NotificationService.prototype.error = function(text, options) {
  this.add(text, TYPES.ERROR, options);
};

/**
 * Public: Add a message to the service.
 *
 * @param {String} text
 * @param {String} type
 * @param {?Object} options
 */
NotificationService.prototype.add = function(text, type, options) {
  var self = this;
  var opts = options || {};
  var existingMessage = _.find(self._messages, function(m) {
    return self.$sce.getTrustedHtml(m.text) == text;
  });

  if (!existingMessage) {
    var message = self._createMessage(text, type, options);

    self._messages.push(message);

    if (opts.skipTimeout) {
      return;
    }

    var defaultTimeout = (message.actionLink ? TIMEOUT_WITH_LINK_MS : TIMEOUT_MS);

    self.$timeout(angular.bind(self, function() {
      self.remove(message);
    }), opts.timeout || defaultTimeout);
  }
};

/**
 * Adds the feedback notification the service.
 * @param {String} text
 * @param {?Object} options
 */
NotificationService.prototype.addFeedbackNotification = function(text, options) {
  this.add(text, TYPES.FEEDBACK, options);
};

/**
 * Returns all messages or just messages of the type passed in.
 *
 * @param {?String} type
 * @returns {[{}]}
 */
NotificationService.prototype.getMessages = function(type) {
  var visibleMessages = this._messages.slice(0, MAX_NUMBER_OF_MESSAGES);
  if (type) {
    return _.filter(visibleMessages, function(message) {
      return message.type == type;
    });
  }

  return visibleMessages;
};

/**
 * Adds a neutral message to the service.
 * @param {String} text
 * @param {?Object} options
 */
NotificationService.prototype.neutral = function(text, options) {
  this.add(text, TYPES.NEUTRAL, options);
};

/**
 * Adds a notice message to the service.
 * @param {String} text
 * @param {?Object} options
 */
NotificationService.prototype.notice = function(text, options) {
  this.add(text, TYPES.NOTICE, options);
};

/**
 * Removes a message from the list of messages.
 *
 * @param {{}} messageToRemove
 */
NotificationService.prototype.remove = function(messageToRemove) {
  this._messages = _.reject(this._messages, function(message) {
    return message == messageToRemove;
  });
};

NotificationService.prototype.removeAll = function() {
  this._messages = [];
};

/**
 * Removes the feedback notification from the service.
 * @param {String} text
 * @param {?Object} options
 */
NotificationService.prototype.removeFeedbackNotification = function() {
  var self = this;
  _.each(self.getMessages(TYPES.FEEDBACK), function(message) {
    self.remove(message);
  });
};

/**
 * Adds a success message to the service.
 * @param {String} text
 * @param {?Object} options
 */
NotificationService.prototype.success = function(text, options) {
  this.add(text, TYPES.SUCCESS, options);
};

/**
 * Adds a font_game_banner message to the service.
 * @param {String} text
 * @param {?Object} options
 */
NotificationService.prototype.font_game_banner = function(text, options) {
  this.add(text, TYPES.FONT_GAME_BANNER, options);
};

NotificationService.prototype.retired_family_banner = function(text, options) {
  this.add(text, TYPES.RETIRED_FAMILY_BANNER, options);
};

NotificationService.prototype.retired_foundry_banner = function(text, options) {
  this.add(text, TYPES.RETIRED_FOUNDRY_BANNER, options);
};

NotificationService.prototype.site_maintenance_banner = function(text, options) {
  this.add(text, TYPES.SITE_MAINTENANCE_BANNER, options);
};

/**
 * Adds a warning message to the service.
 * @param {String} text
 * @param {?Object} options
 */
NotificationService.prototype.warn = function(text, options) {
  this.add(text, TYPES.WARN, options);
};

/**
 * Displays the message as either a notice or error, depending on the displayError argument
 * @param {String} message
 * @param {Boolean} displayError
 */
NotificationService.prototype.toggleErrorMessage = function(message, displayError) {
  if (displayError) {
    this.error(message);
  } else {
    this.notice(message);
  }
};

/**
 * Creates a message.
 *
 * @private
 * @param {String} text
 * @param {String} type
 * @param {Object} opts
 * @returns {{
 *  id: {Number},
 *  text: {String},
 *  type: {String}
 * }}
 */
NotificationService.prototype._createMessage = function(text, type, opts) {
  opts = opts || {};
  if (!type) {
    type = TYPES.NOTICE;
  }

  var actionLink;
  var retirementMessages;
  var actionLabel;
  var callbackAction;
  var bannerColor;

  if (opts.actionLabel && opts.retirementMessages) {
    actionLabel = opts.actionLabel;

    retirementMessages = _.map(opts.retirementMessages, function(retired) {
      return {
        slug: retired.slug,
        href: retired.href,
        name: retired.name,
        retirement_date: new Date(retired.retirement_date).toLocaleDateString(retired.locale, {year: 'numeric', month: 'long', day: 'numeric'})
      };
    });
  }

  if (opts.actionLink && opts.actionLabel) {
    actionLink = opts.actionLink;
    actionLabel = opts.actionLabel;
  }

  if (opts.callbackAction && opts.actionLabel) {
    actionLabel = opts.actionLabel;
    callbackAction = opts.callbackAction;
  }

  if (opts.bannerColor) {
    bannerColor = opts.bannerColor;
  }

  return {
    id: this._nextNotificationId++,
    text: this.$sce.trustAsHtml(text),
    actionLink: actionLink,
    actionLabel: actionLabel,
    callbackAction: callbackAction,
    category: opts.category,
    queryParams: opts.queryParams,
    scriptIdentifier: opts.scriptIdentifier,
    tooltipText: opts.tooltipText,
    type: type,
    bannerColor: bannerColor,
    retirementMessages: retirementMessages
  };
};
