var _ = require('underscore');
var VALID_FONT_STYLES = [
  'normal', 'italic', 'oblique'
];

var CLOSEST_FONT_STYLE = {
  'normal':  ['normal', 'italic', 'oblique'],
  'italic':  ['italic', 'oblique', 'normal'],
  'oblique': ['oblique', 'italic', 'normal']
};

/**
 * Gets the numeric weight from an fvd string.
 * @param {String} fvd like "n4".
 * @returns {Integer|null}.
 */
function getFontWeightFromFvd(fvd) {
  if (fvd) {
    var value = parseInt(fvd.slice(1)) * 100;
    return isNaN(value) ? null : value;
  } else {
    return null;
  }
}

/**
 * Get the font style string from an fvd string.
 * @param {String} fvd like "n4".
 * @returns {String|null}.
 */
function getFontStyleFromFvd(fvd) {
  if (fvd) {
    return _.detect(VALID_FONT_STYLES, function(style) {
      return style.slice(0, 1) == fvd.slice(0, 1);
    });
  } else {
    return null;
  }
}

/**
 * Get all of the fvds from the complete set that have a given weight.
 *
 * @param {Integer} weight font weight.
 * @param {String[]} fvds Array of String fvds that are available.
 *
 * @returns {String[]} fvds with matching weight.
 */
function findFvdsWithWeight(weight, fvds) {
  return _.filter(fvds, function(fvd) { return getFontWeightFromFvd(fvd) == weight; });
}

/**
 * Get the fvd from a set that's closest to a target
 *
 * @param {String} targetFvd fvd you'd like to match.
 * @param {String[]} fvds Array of fvds that are available.
 *
 * @returns {String|null};
 */
function findClosestFvd(targetFvd, fvds) {
  if (!targetFvd || !fvds.length) {
    return null;
  }

  // The weight and style we're trying to achieve.
  var targetWeight = getFontWeightFromFvd(targetFvd);
  var targetStyle = getFontStyleFromFvd(targetFvd);

  // Build an index of fvds by their weight/style.
  var index = {};
  _.each(fvds, function(fvd) {
    var weight = getFontWeightFromFvd(fvd);
    var style = getFontStyleFromFvd(fvd);
    if (!index[weight]) index[weight] = {};
    index[weight][style] = fvd;
  }, this);

  // Find the closest weight to target.
  var currentWeight = targetWeight;
  var direction = targetWeight >= 500 ? 1 : -1;
  var step = 1;
  while (step < 20) {
    if (index[currentWeight]) break;
    currentWeight += step * direction * 100;
    direction *= -1;
    step += 1;
  }

  // Get a ranked list of style preferences.
  var closestStyles = (CLOSEST_FONT_STYLE[targetStyle] || []);

  // Find the closest style at the current weight.
  for (var i = 0, len = closestStyles.length; i < len; i++) {
    var closestFvd = index[currentWeight][closestStyles[i]];
    if (closestFvd) {
      return closestFvd;
    }
  }

  // Shouldn't happen. If it does, write a test.
  return null;
}

/**
 * Get the set of fvds from a complete set that are closest to the
 * basic four. Looks for the closest to "n4" and "n7" and then includes the
 * italic styles at the same weight if they're available. Can return between
 * one and four fvds depending on which are in the original family.
 *
 * @param {String[]} fvds - Array of fvds that are available.
 * @returns {String[]} an Array of fvd Strings.
 */
function findClosestToBasicFour(fvds) {
  var basicFourFvds = [];

  _.each(["n4", "n7"], function(targetFvd) {
    var closestFvd = findClosestFvd(targetFvd, fvds);
    if (closestFvd) {
      var closestWeight = getFontWeightFromFvd(closestFvd);
      basicFourFvds = basicFourFvds.concat(findFvdsWithWeight(closestWeight, fvds));
    }
  });

  return _.uniq(basicFourFvds);
}

/**
 * Sorts a set of fvd into standard order, where lighter weights are
 * before heavier weights, and within a weight, styles are sorted in
 * normal > italic > oblique order.
 *
 * @param {String[]} fvds - An Array of String fvds.
 * @returns {String[]} a new Array of sorted String fvds.
 */
function sortFvds(fvds) {
  return fvds.slice(0).sort(compareFvds);
}

/**
 * Compares two fvds and returns 0 if they're the same, a positive
 * integer if the first fvd is "greater" than the second, and a negative
 * integer if the reverse is true. Useful for sorting fvds into the expected
 * light to heavy order, as in sortFvds above.
 *
 * @param {String} fvd1 - First String fvd to compare.
 * @param {String} fvd2 - Second String fvd to compare.
 * @returns {Integer} an Integer result of comparing the two.
 */
function compareFvds(fvd1, fvd2) {
  var weight1 = getFontWeightFromFvd(fvd1);
  var weight2 = getFontWeightFromFvd(fvd2);
  if (weight1 && weight2) {
    if (weight1 != weight2) {
      return weight1 - weight2;
    } else {
      var style1 = getFontStyleFromFvd(fvd1);
      var style2 = getFontStyleFromFvd(fvd2);
      if (style1 && style2) {
        return _.indexOf(VALID_FONT_STYLES, style1) - _.indexOf(VALID_FONT_STYLES, style2);
      }
    }
  }

  return 0;
}

module.exports = {
  compareFvds: compareFvds,
  findClosestFvd: findClosestFvd,
  findClosestToBasicFour: findClosestToBasicFour,
  findFvdsWithWeight: findFvdsWithWeight,
  getFontStyleFromFvd: getFontStyleFromFvd,
  getFontWeightFromFvd: getFontWeightFromFvd,
  sortFvds: sortFvds
};
