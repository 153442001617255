var DATA_ID = 'user-pref-data';
var _ = require('underscore');

var CACHED_PREFS;
var THROTTLE_MS = 500;

const DEFAULT_SAMPLE_FONT_SIZE = 36;

/**
 * A service for loading user preference data if it's available.
 *
 * The user preference data should be loaded on every page inside of a script
 * tag with the ID defined by DATA_ID.
 *
 * @ngInject
 */
function UserPrefsService($http, $q, DataService) {
  return {
    clearCache: clearCache,
    getUserPrefs: getUserPrefs,
    updatePrefs: _.throttle(updatePrefs, THROTTLE_MS)
  };

  /**
   * Clears the user prefs that are cached on the front end.
   */
  function clearCache() {
    CACHED_PREFS = null;
  }

  /**
   * Get the user's stored preferences using the data service.
   * @returns {Promise}
   */
  function getUserPrefs() {
    return $q(function(resolve) {
      if (CACHED_PREFS) {
        resolve(CACHED_PREFS);
      } else {
        DataService.get(DATA_ID).then(function(data) {
          CACHED_PREFS = _getFormattedPrefData(data);
          resolve(CACHED_PREFS);
        });
      }
    });
  }

  /**
   * Updates the preferences for a user
   *
   * @param {{
   *   exampleText: {
   *     value: {String},
   *     size: {Number}
   *   },
   *   isFilterListHiddenByUser: {Boolean},
   *   selectedViewType: {String},
   * }} prefsToUpdate
   *
   * @returns {Http.promise}
   */
  function updatePrefs(prefsToUpdate) {
    // Make sure the cached version of the prefs gets updated on the front end
    // so that changes will persist without having to reload the page.
    getUserPrefs().then(function(currentPrefs) {
      CACHED_PREFS = _.extend(currentPrefs, prefsToUpdate);
    });

    return $http.put('/account/prefs.json', _getHttpParams(prefsToUpdate));
  }
}

/**
 * Take the raw params a method call and format them for an HTTP request.
 *
 * @private
 * @param params {Object}
 * @returns {Object}
 */
function _getHttpParams(params) {
  var formatted = {};

  if (params.exampleText) {
    formatted.browse_families_sample_text = params.exampleText.value;
    formatted.browse_families_sample_size = params.exampleText.size;
  }

  if (params.selectedViewType) {
    formatted.browse_families_view_type = params.selectedViewType;
  }

  if (params.isFilterListHiddenByUser) {
    formatted.browse_filter_drawer_state = 'close';
  } else {
    formatted.browse_filter_drawer_state = 'open';
  }

  if (params.browseMode) {
    formatted.browse_families_browse_mode = params.browseMode;
    // When toggling browseMode, reset the sample text details
    // so that default Japanese and english strings appear accordingly.
    if (!params.exampleText) {
      formatted.browse_families_sample_text = '';
      formatted.browse_families_sample_size = 'null';
    }
  }

  if (params.hideTypekitOnboardModal) {
    formatted.hide_typekit_onboard_modal = true;
  }

  if (params.hideFontActivationModal) {
    formatted.hide_font_activation_modal = true;
  }

  if (params.hideUseModelFontActivationModal) {
    formatted.hide_use_model_font_activation_modal = true;
  }

  if (params.hideUseModelCCDTutorial) {
    formatted.hide_use_model_ccd_tutorial = true;
  }

  if (params.hideLibrariesCoachmark) {
    formatted.hide_libraries_coachmark = params.hideLibrariesCoachmark;
  }

  if (params.hideRemoveFamilyConfirmationModal) {
    formatted.hide_remove_family_confirmation_modal = true;
  }

  if (params.hideExpiredFontsModal) {
    formatted.hide_expired_fonts_modal = true;
  }

  if (params.hideStandaloneSubscriptionNotification) {
    formatted.hide_standalone_subscription_notification = true;
  }

  if (params.hideFamilyRetirementNotification) {
    formatted.hide_family_retirement_notification = params.hideFamilyRetirementNotification;
  }

  if (params.hideFoundryRetirementNotification) {
    formatted.hide_foundry_retirement_notification = params.hideFoundryRetirementNotification;
  }

  formatted.hide_images = params.hideImages;
  formatted.hide_remove_from_favorites_dialog = params.hideRemoveFromFavoritesDialog;
  formatted.hide_remove_from_library_dialog = params.hideRemoveFromLibraryDialog;
  formatted.show_basic_library = params.showBasicLibrary;
  formatted.show_paid_fonts = params.showPaidFonts;
  formatted.gpt_search_version = params.gptSearchVersion;
  formatted.gpt_search_deployment = params.gptSearchDeployment;
  formatted.gpt_search_system_prompt = params.gptSearchSystemPrompt;
  formatted.gpt_search_user_prompt_prefix = params.gptSearchUserPromptPrefix;
  formatted.gpt_search_temperature = params.gptSearchTemperature;

  return {prefs: formatted};
}

/**
  * Get a formatted version of the user's stored preferences.
  *
  * @private
  * @param {{
  *   browse_families_sample_text: {String},
  *   browse_families_sample_size: {String},
  *   browse_families_view_type: {String},
  *   browse_families_browse_mode: {String},
  *   browse_filter_drawer_state: {String},
  *   hide_typekit_onboard_modal: {Boolean}
  * }} userPrefs
  *
  * @returns {{exampleText: {value: (any), size: (any)}, isFilterListHiddenByUser: boolean, selectedViewType: (any)}|{}}
  */
function _getFormattedPrefData(userPrefs) {
  var state = {};
  var _userPrefs = _.pick(userPrefs,
    'browse_families_sample_text',
    'browse_families_sample_size',
    'browse_families_view_type',
    'browse_families_browse_mode',
    'browse_filter_drawer_state',
    'hide_typekit_onboard_modal',
    'hide_font_activation_modal',
    'hide_libraries_coachmark',
    'hide_use_model_ccd_tutorial',
    'hide_use_model_font_activation_modal',
    'hide_remove_family_confirmation_modal',
    'hide_expired_fonts_modal',
    'hide_images',
    'show_paid_fonts',
    'show_basic_library',
    'gpt_search_version',
    'gpt_search_deployment',
    'gpt_search_system_prompt',
    'gpt_search_user_prompt_prefix',
    'gpt_search_temperature'
  );

  state.exampleText = {
    value: _userPrefs.browse_families_sample_text,
    size: _userPrefs.browse_families_sample_size || DEFAULT_SAMPLE_FONT_SIZE
  };

  if (_userPrefs.browse_filter_drawer_state) {
    state.isFilterListHiddenByUser = _userPrefs.browse_filter_drawer_state === 'close';
  }

  if (_userPrefs.browse_families_view_type) {
    state.selectedViewType = _userPrefs.browse_families_view_type;
  }

  if (_userPrefs.browse_families_browse_mode) {
    state.browseMode = _userPrefs.browse_families_browse_mode;
  }

  if (_userPrefs.hide_typekit_onboard_modal) {
    state.hideTypekitOnboardModal = _userPrefs.hide_typekit_onboard_modal === 'true';
  }

  if (_userPrefs.hide_font_activation_modal) {
    state.hideFontActivationModal = _userPrefs.hide_font_activation_modal === 'true';
  }

  if (_userPrefs.hide_use_model_font_activation_modal) {
    state.hideUseModelFontActivationModal = _userPrefs.hide_use_model_font_activation_modal === 'true';
  }

  if (_userPrefs.hide_use_model_ccd_tutorial) {
    state.hideUseModelCCDTutorial = _userPrefs.hide_use_model_ccd_tutorial === 'true';
  }

  if (_userPrefs.hide_libraries_coachmark) {
    state.hideLibrariesCoachmark = _userPrefs.hide_libraries_coachmark;
  }

  if (_userPrefs.hide_remove_family_confirmation_modal) {
    state.hideRemoveFamilyConfirmationModal = _userPrefs.hide_remove_family_confirmation_modal === 'true';
  }

  if (_userPrefs.hide_expired_fonts_modal) {
    state.hideExpiredFontsModal = _userPrefs.hide_expired_fonts_modal === 'true';
  }

  if (_userPrefs.hide_images) {
    state.hideImages = _userPrefs.hide_images === 'true';
  }

  if (_userPrefs.show_paid_fonts) {
    state.showPaidFonts = _userPrefs.show_paid_fonts === 'true';
  }

  if (_userPrefs.show_basic_library) {
    state.showBasicLibrary = _userPrefs.show_basic_library === 'true';
  }

  if (Object.prototype.hasOwnProperty.call(_userPrefs, 'gpt_search_version')) {
    state.gptSearchVersion = _userPrefs.gpt_search_version;
  }

  if (Object.prototype.hasOwnProperty.call(_userPrefs, 'gpt_search_deployment')) {
    state.gptSearchDeployment = _userPrefs.gpt_search_deployment;
  }

  if (Object.prototype.hasOwnProperty.call(_userPrefs, 'gpt_search_system_prompt')) {
    state.gptSearchSystemPrompt = _userPrefs.gpt_search_system_prompt;
  }

  if (Object.prototype.hasOwnProperty.call(_userPrefs, 'gpt_search_user_prompt_prefix')) {
    state.gptSearchUserPromptPrefix = _userPrefs.gpt_search_user_prompt_prefix;
  }

  if (Object.prototype.hasOwnProperty.call(_userPrefs, 'gpt_search_temperature')) {
    state.gptSearchTemperature = _userPrefs.gpt_search_temperature;
  }

  return state;
}


module.exports = UserPrefsService;
