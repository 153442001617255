const COOKIE_ID_SAW_KOREAN_BROWSE_FONTS_DIALOG = 'adobe-fonts-saw-korean-browse-fonts-dialog';
const KOREAN_LOCALE = 'ko_KR';

/**
 * A service for displaying first mile dialog's for various use cases.
 *
 * @ngInject
 */
function FirstMileService($cookies, $q, DialogService, I18nService, NewrelicService, NotificationService, UserPrefsService) {
  return {
    handlePageLoad: handlePageLoad,
    shouldShowFontActivationDialog: shouldShowFontActivationDialog,
    shouldShowUseModelFontActivationDialog: shouldShowUseModelFontActivationDialog,
    shouldShowTypekitOnboardDialog: shouldShowTypekitOnboardDialog,
    showExpiredFontsDialog: showExpiredFontsDialog,
    showFontActivationDialog: showFontActivationDialog,
    showTypekitOnboardDialogIfNecessary: showTypekitOnboardDialogIfNecessary,
    showKoreanBrowseFontsDialog: showKoreanBrowseFontsDialog,
    showUseModelFontActivationDialog: showUseModelFontActivationDialog,
    showCcdLaunchFailureDialog: showCcdLaunchFailureDialog,
    shouldShowRemoveFamilyConfirmationDialog: shouldShowRemoveFamilyConfirmationDialog,
    showRemoveFamilyConfirmationDialog: showRemoveFamilyConfirmationDialog,
    showCcdInstallDialog: showCcdInstallDialog,
    onUseModelFontActivation: onUseModelFontActivation,
    onUseModelFontActivationSuccess: onUseModelFontActivationSuccess,
    openCreativeCloudDesktop: openCreativeCloudDesktop
  };

  /**
   * At page load time, determine if any of the first mile dialog's should be shown.
   */
  function handlePageLoad() {
    var self = this;
    I18nService.getLocaleInfo().then(function(data) {
      if (shouldShowKoreanBrowseFontsDialog(data.selectedLocale)) {
        self.showKoreanBrowseFontsDialog();
      }
    });
  }

  /**
   * Should the user see the font activation modal?
   * @return {Promise}
   */
  function shouldShowFontActivationDialog() {
    return UserPrefsService.getUserPrefs().then(function(userPrefs) {
      return !userPrefs.hideFontActivationModal;
    });
  }

  /**
   * Should the user see the use model font activation modal?
   * @return {Promise}
   */
  function shouldShowUseModelFontActivationDialog() {
    return UserPrefsService.getUserPrefs().then(function(userPrefs) {
      return !userPrefs.hideUseModelFontActivationModal;
    });
  }

  /**
   * Should the user see the Typekit Remove Family Confirmation modal?
   * @return {Promise}
   */
  function shouldShowRemoveFamilyConfirmationDialog() {
    return UserPrefsService.getUserPrefs().then(function(userPrefs) {
      return !userPrefs.hideRemoveFamilyConfirmationModal;
    });
  }

  /**
   * Should the user see the Typekit onboarding modal?
   * @return {Promise}
   */
  function shouldShowTypekitOnboardDialog() {
    return UserPrefsService.getUserPrefs().then(function(userPrefs) {
      return !userPrefs.hideTypekitOnboardModal;
    });
  }

  function shouldShowKoreanBrowseFontsDialog(selectedLocale) {
    // Don't show the dialog if the user has already seen it.
    if ($cookies.get(COOKIE_ID_SAW_KOREAN_BROWSE_FONTS_DIALOG)) {
      return false;
    }
    return selectedLocale === KOREAN_LOCALE;
  }

  /**
   * Shows the Typekit onboarding dialog if the user hasn't already seen it.
   * @return {Promise}
   */
  function showTypekitOnboardDialogIfNecessary() {
    return $q(function(resolve) {
      shouldShowTypekitOnboardDialog().then(function(shouldShow) {
        // If the onboarding dialog doesn't need to be displayed, resolve the
        // promise right away.
        if (!shouldShow) {
          resolve();
          return;
        }

        // Otherwise show the dialog and resolve the promise when the user
        // clicks the OK button
        DialogService.show('/angular_templates/dialogs/typekit_onboard_modal.html').then(function(dialog) {
          dialog.handleOKButton = function() {
            UserPrefsService.updatePrefs({ hideTypekitOnboardModal: true });
            DialogService.close();
            resolve();
          };
        });
      });
    });
  }

  /**
   * Shows the dialog that the user sees after activating a font.
   */
  function showFontActivationDialog() {
    DialogService.show('/angular_templates/dialogs/font_activation_first_mile.html').then(function(dialog) {
      dialog.handleOKButton = function() {
        if (dialog.optOutOfModal) {
          UserPrefsService.updatePrefs({ hideFontActivationModal: true });
        }
        DialogService.close();
      };
    });
  }

  function onUseModelFontActivation(i18n, opts = {}) {
    const successNotificationCallback = onUseModelFontActivationSuccess(i18n, opts);

    return shouldShowUseModelFontActivationDialog().then(shouldShowDialog => {
      if (shouldShowDialog) {
        showUseModelFontActivationDialog(successNotificationCallback, opts.queryParams);
      } else {
        successNotificationCallback();
      }
    });
  }

  function onUseModelFontActivationSuccess(i18n, opts = {}) {
    const { namespace = 'neue.browse.family_card', queryParams } = opts;
    return () => {
      const notificationOptions = {
        actionLink: 'open ccd',
        actionLabel: i18n[namespace]['open_app'],
        queryParams,
        timeout: 16000
      };
      NotificationService.success(i18n[namespace]['fonts_added_success_message'], notificationOptions);
    };
  }
  /**
   * Shows the dialog that the user sees after activating a font with use model v2.
   */
  function showUseModelFontActivationDialog(successNotificationCallback, queryParams) {
    DialogService.show('/angular_templates/dialogs/font_activation_first_mile_use_model.html').then(function(dialog) {
      dialog.close = function() {
        NewrelicService.addPageAction('typekit.click.use-model-font-activation-close');
        if (dialog.optOutOfModal) {
          NewrelicService.addPageAction('typekit.click.use-model-font-activation-opt-out');
          UserPrefsService.updatePrefs({ hideUseModelFontActivationModal: true });
        }
        DialogService.close();
        if (successNotificationCallback) {
          successNotificationCallback();
        }
      };

      dialog.handleOKButton = function() {
        NewrelicService.addPageAction('typekit.click.use-model-font-activation-open-app', { source: 'Modal Dialog' });
        if (dialog.optOutOfModal) {
          NewrelicService.addPageAction('typekit.click.use-model-font-activation-opt-out');
          UserPrefsService.updatePrefs({ hideUseModelFontActivationModal: true });
        }
        DialogService.close();
        openCreativeCloudDesktop(queryParams);
        if (successNotificationCallback) {
          successNotificationCallback();
        }
      };
    });
  }

  /**
   * Open the CCD
   */
  function openCreativeCloudDesktop(queryParams) {
    // WAMClient is a very large library (~30kb), and won't be needed by most
    // users, so it should be delay loaded an initialized on the fly.
    import('../../util/WAMClient.js').then(({ openCCD }) => {
      openCCD(function(isCCDConnectionSuccessful, isCCDPresent) {
        if (!isCCDPresent || !isCCDConnectionSuccessful) {
          showCcdLaunchFailureDialog();
        }
      }, queryParams);
    });
  }

  /**
   * Shows the dialog when CCD fails to launch.
   */
  function showCcdLaunchFailureDialog() {
    NewrelicService.addPageAction('typekit.click.use-model-font-activation-open-app-failure');
    NotificationService.removeAll();
    DialogService.show('/angular_templates/dialogs/ccd_launch_failure_modal.html').then(function(dialog) {
      dialog.handleOkButtonClick = function() {
        dialog.close();
      };
    });
  }

  /**
   * Shows the dialog when CCD is not detected.
   */
  function showCcdInstallDialog() {
    NewrelicService.addPageAction('typekit.click.use-model-cc-install-modal');
    DialogService.show('/angular_templates/dialogs/ccd_install_modal.html').then(function(dialog) {

      dialog.handleCancelButton = function() {
        dialog.close();
      };
    });
  }

  /**
   * Shows the dialog that the user sees when removing font or font family.
   */
  function showRemoveFamilyConfirmationDialog(dialogHeader, dialogDescription, callback) {
    DialogService.show('/angular_templates/dialogs/remove_family_confirmation.html').then(function(dialog) {
      dialog.heading = dialogHeader;
      dialog.description = dialogDescription;

      dialog.handleCancelButton = function() {
        NewrelicService.addPageAction('typekit.click.remove-family-dialog-cancel');
        if (dialog.optOutOfModal) {
          UserPrefsService.updatePrefs({ hideRemoveFamilyConfirmationModal: true });
        }
        DialogService.close();
      };

      dialog.handleRemoveButton = function() {
        if (dialog.optOutOfModal) {
          UserPrefsService.updatePrefs({ hideRemoveFamilyConfirmationModal: true });
        }
        DialogService.close();
        callback();
      };
    });
  }

  function showExpiredFontsDialog() {
    DialogService.show('/angular_templates/dialogs/expired_fonts_first_mile.html').then(function(dialog) {
      dialog.close = function() {
        UserPrefsService.updatePrefs({ hideExpiredFontsModal: true });
        DialogService.close();
      };
    });
  }

  function showKoreanBrowseFontsDialog() {
    DialogService.show('/angular_templates/dialogs/korean_browse_fonts_modal.html').then(function(dialog) {
      dialog.handleOKButton = function() {
        if (dialog.optOutOfModal) {
          $cookies.put(COOKIE_ID_SAW_KOREAN_BROWSE_FONTS_DIALOG, true, {
            expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1))
          });
        }

        DialogService.close();
      };
    });
  }
}

module.exports = FirstMileService;
