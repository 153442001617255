/**
 * A service for displaying a dialog to users with suspended Creative Cloud accounts
 *
 * @ngInject
 */
function SuspendedAccountService(DataService, DialogService) {
  return {
    handlePageLoad: handlePageLoad
  };

  function handlePageLoad() {
    DataService.get('suspended-account-data').then(function(data) {
      if (data.hasSuspendedAccount) {
        DialogService.show('/angular_templates/dialogs/account_suspended.html', { closeDisabled: true });
      }
    });
  }
}

module.exports = SuspendedAccountService;
