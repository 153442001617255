var fvdUtil = require('../../util/fvd.js');
var angular = require('angular');

/**
 * A class that uses typekit font families data and manages a list of selected styles.
 *
 * @constructor
 * @param {Array} families
 * @param {Object} textSamples
 * @param {String} exampleText
 * @ngInject
 */
function StyleSelectorModel(families, textSamples, exampleText) {
  this._families = angular.copy(families);
  this._textSamples = textSamples;
  this._exampleText = exampleText;
  this.sortFamiliesAndVariations();
  this.initDisplayVariations();
  this.initCheckedState();
}

/**
 * Returns the selected variations grouped by families.
 *
 * @returns {Array}
 */
StyleSelectorModel.prototype.getSelectedVariations = function() {
  var self = this;
  var selectedVariations = [];
  if (self.isAnyVariationSelected()) {
    Object.keys(self._checkedState.families).forEach(function (opaqueId) {
      if (self._checkedState.families[opaqueId].checkedVariationCount > 0) {
        var fontVariations = Object.keys(self._checkedState.families[opaqueId].variations)
          .map(function (variationId) {
            return Number(variationId);
          })
          .filter(function (variationId) {
            return self._checkedState.families[opaqueId].variations[variationId];
          });
        selectedVariations.push({
          opaque_id: opaqueId,
          font_variations: fontVariations
        });
      }
    });
  }
  return selectedVariations;
};

/**
 * Is any variation selected?
 *
 * @returns {Boolean}
 */
StyleSelectorModel.prototype.isAnyVariationSelected = function() {
  return this._checkedState.checkedVariationCount !== 0;
};

/**
 * Returns the sorted families.
 *
 * @returns {Array}
 */
StyleSelectorModel.prototype.getSortedFamilies = function() {
  return this._families;
};

/**
 * Returns the example text.
 *
 * @returns {String}
 */
StyleSelectorModel.prototype.getExampleText = function() {
  return this._exampleText;
};

/**
 * Returns the number of variations in a given family yet to be shown.
 *
 * @param {Object} family
 * @returns {Number}
 */
StyleSelectorModel.prototype.getShowMoreCount = function(family) {
  return family.variations.length - this.getDisplayVariations(family).length;
};

/**
 * Returns the variations to be displayed for a given family.
 *
 * @param {Object} family
 * @returns {Array}
 */
StyleSelectorModel.prototype.getDisplayVariations = function(family) {
  return this._displayVariations[family.web_id];
};

/**
 * Sets the display variations for a given family to a given new value.
 *
 * @param {String} familyId
 * @param {Array} newDisplayVariations
 */
StyleSelectorModel.prototype.setDisplayVariations = function(familyId, newDisplayVariations) {
  this._displayVariations[familyId] = newDisplayVariations;
};

/**
 * Is the given variation selected?
 *
 * @param {Object} variation
 * @return {boolean}
 */
StyleSelectorModel.prototype.isVariationSelected = function(variation) {
  return this._checkedState.families[variation.family.web_id].variations[variation.id];
};

/**
 * Returns the number of variations selected in a given family.
 *
 * @param {String} familyId
 * @return {Number}
 */
StyleSelectorModel.prototype.getSelectedVariationCount = function(familyId) {
  return this._checkedState.families[familyId].checkedVariationCount;
};

/**
 * Returns the total number of variations in a given family.
 *
 * @param {String} familyId
 * @return {Number}
 */
StyleSelectorModel.prototype.getTotalVariationCount = function(familyId) {
  return this._checkedState.families[familyId].totalVariationCount;
};

/**
 * Toggles the selection state for the given variation.
 *
 * @param {Object} variation
 */
StyleSelectorModel.prototype.toggleCheckbox = function(variation) {
  var familyId = variation.family.web_id;
  this._checkedState.families[familyId].variations[variation.id] = !this._checkedState.families[familyId].variations[variation.id];
  var change = this._checkedState.families[familyId].variations[variation.id] ? 1 : -1;
  this._checkedState.families[familyId].checkedVariationCount += change;
  this._checkedState.checkedVariationCount += change;
};

/**
 * Returns an object used to render the given font variation using the display-in-font directive.
 *
 * @returns {{
 *   cssName: string,
 *   fvd: string,
 *   isDynamic: boolean,
 *   opaqueId: string,
 *   unicodeRange: string,
 *   featureSettings: string,
 *   kanaOnly: boolean
 * }}
 */
StyleSelectorModel.prototype.getVariationDisplayData = function(variation) {
  var unicodeRange = this._textSamples[variation.default_language].unicode_range;
  var featureSettings = this._textSamples[variation.default_language].feature_settings;
  var kanaOnly = this._textSamples[variation.default_language].kana_only;

  if (!kanaOnly) {
    kanaOnly = false;
  }

  return {
    cssName: variation.family.web_id + '-' + variation.font.web.fvd,
    fvd: variation.font.web.fvd,
    isDynamic: false,
    opaqueId: variation.family.web_id,
    unicodeRange: unicodeRange,
    featureSettings: featureSettings,
    kanaOnly: kanaOnly
  };
};

/**
 * Sorts given variations according to their fvd's.
 *
 * @private
 * @param {Array} variations
 */
StyleSelectorModel.prototype.sortVariations = function(variations) {
  variations.sort(function(variation1, variation2) {
    return fvdUtil.compareFvds(variation1.font.web.fvd, variation2.font.web.fvd);
  });
};

/**
 * Sorts given families and their variations.
 *
 * @private
 */
StyleSelectorModel.prototype.sortFamiliesAndVariations = function () {
  var self = this;
  self._families.forEach(function (family) {
    self.sortVariations(family.variations);
  });
};

/**
 * Initializes the display variations for each family.
 *
 * @private
 */
StyleSelectorModel.prototype.initDisplayVariations = function() {
  var self = this;
  self._displayVariations = {};
  self._families.forEach(function(family) {
    var fvds = family.variations.map(function (variation) {
      return variation.font.web.fvd;
    });
    var defaultSelectedFvds = fvdUtil.findClosestToBasicFour(fvds);
    self._displayVariations[family.web_id] = family.variations.filter(function (variation) {
      return defaultSelectedFvds.indexOf(variation.font.web.fvd) !== -1;
    });
    self.sortVariations(self._displayVariations[family.web_id]);
  });
};

/**
 * Initializes the state of checkboxes.
 *
 * @private
 */
StyleSelectorModel.prototype.initCheckedState = function() {
  var self = this;
  self._checkedState = { checkedVariationCount: 0, totalVariationCount: 0, families: {}};
  self._families.forEach(function(family) {
    self._checkedState.totalVariationCount += family.variations.length;
    self._checkedState.families[family.web_id] = { checkedVariationCount: 0, totalVariationCount: family.variations.length, variations: {}};
    family.variations.forEach(function (variation) {
      self._checkedState.families[family.web_id].variations[variation.id] = false;
      if (self.getDisplayVariations(family).indexOf(variation) !== -1) {
        self._checkedState.families[family.web_id].variations[variation.id] = true;
        self._checkedState.families[family.web_id].checkedVariationCount += 1;
      }
    });
    self._checkedState.checkedVariationCount += self._checkedState.families[family.web_id].checkedVariationCount;
  });
};

module.exports = StyleSelectorModel;
