var EmbedCodeModel = require('../models/embed_code_model.js');
var WebfontProjectStylesModel = require('../models/webfont_project_styles_model.js');

/**
 * A class that manages "add fonts to web project" modal.
 *
 * @constructor
 * @param {Object} $http
 * @param {Object} DialogService
 * @param {Promise} deferredFamilyAndProjectsInfo
 * @param {Object} projectSelectorModel
 * @param {Object} styleSelectorModel
 * @ngInject
 */
function AddFontsToWebProjectModel($http, DialogService, deferredFamilyAndProjectsInfo, projectSelectorModel, styleSelectorModel) {
  var self = this;
  self.$http = $http;
  self.DialogService = DialogService;
  self._projectById = [];
  self._projectSelectorModel = projectSelectorModel;
  self._styleSelectorModel = styleSelectorModel;
  self._isFamilyCJK = false;
  self._saveAttempted = false;
  self._saved = false;
  self._addedFontsCount = 0;
  self._addedToProjectName = '';
  self._addedToProjectEditorLink = '';
  self._embedCodeModel = null;
  self._webfontProjectStylesModel = null;
  self._stylesData = null;
  self.existingFamiliesInProject = [];
  self._populateFamilyAndProjectsInfo(deferredFamilyAndProjectsInfo);
}

/**
 * Returns id of the selected project or null if an existing project isn't selected.
 *
 * @return {String}
 */
AddFontsToWebProjectModel.prototype.getSelectedProjectID = function () {
  return this._projectSelectorModel.getSelectedProjectID();
};

/**
 * Returns name of the selected project or null if an existing project isn't selected.
 *
 * @return {String}
 */
AddFontsToWebProjectModel.prototype.getSelectedProjectName = function () {
  var selectedProject = this._projectById[this._projectSelectorModel.getSelectedProjectID()];
  if (selectedProject) {
    return selectedProject.name;
  }
};

/**
 * Are there any families in the selected project?
 *
 * @return {Boolean}
 */
AddFontsToWebProjectModel.prototype.doFamiliesExistInProject = function () {
  var selectedProject = this._projectById[this._projectSelectorModel.getSelectedProjectID()];
  if (selectedProject && this.getExistingFamiliesInProject().length > 0) {
    return true;
  }
  return false;
};

/**
 * Return names of the existing families in the selected project.
 *
 * @return {Array}
 */
AddFontsToWebProjectModel.prototype.getExistingFamiliesInProject = function () {
  return this.existingFamiliesInProject;
};

/**
 * Updates the existing families in a project
 *
 * @param {Object}
 */
AddFontsToWebProjectModel.prototype.updateExistingFamiliesInProject = function(families) {
  this.existingFamiliesInProject = (families && families.length > 0) ? families : [];
};

/**
 * Is the current typographic family CJK?
 *
 * @return {Boolean}
 */
AddFontsToWebProjectModel.prototype.isFamilyCJK = function () {
  return this._isFamilyCJK;
};

/**
 * Close the currently open dialog/modal.
 */
AddFontsToWebProjectModel.prototype.closeDialog = function () {
  this.DialogService.close();
};

/**
 * Did the save attempt succeed?
 *
 * @return {Boolean}
 */
AddFontsToWebProjectModel.prototype.didSaveSucceed = function () {
  return this._saved;
};

/**
 * Is it valid to save?
 *
 * @return {Boolean}
 */
AddFontsToWebProjectModel.prototype.isSaveValid = function () {
  return Boolean(this._styleSelectorModel.isAnyVariationSelected() &&
    (this._projectSelectorModel.getSelectedProjectID() ||
      this._projectSelectorModel.isCurrentProjectNameValid()));
};

/**
 * Was there an attempt made to save?
 *
 * @return {Boolean}
 */
AddFontsToWebProjectModel.prototype.didAttemptSave = function () {
  return this._saveAttempted;
};

/**
 * Tries to save the selected fonts to the selected project (or new project).
 * Then creates an instance of EmbedCodeModel and StylesModel to prepare
 * for the info display page.
 */
AddFontsToWebProjectModel.prototype.save = function () {
  var self = this;
  if (self._saveAttempted) {
    return;
  }

  self._saveAttempted = true;

  var selectedVariations = self._styleSelectorModel.getSelectedVariations();
  if (self._projectSelectorModel.getSelectedProjectID()) {
    self._addSelectedToExistingProject(
      selectedVariations, self._projectSelectorModel.getSelectedProjectID()).then(function(projectUsageCode) {
        self._handleSaveSuccess(projectUsageCode);
      });
  } else if (self._projectSelectorModel.isCurrentProjectNameValid()) {
    self._addSelectedToNewProject(
      selectedVariations, self._projectSelectorModel.getProjectName()).then(function(projectUsageCode) {
        self._handleSaveSuccess(projectUsageCode);
      });
  }
};

/**
 * Returns the existing instance of EmbedCodeModel.
 *
 * @returns {Object}
 */
AddFontsToWebProjectModel.prototype.getEmbedCodeModel = function () {
  return this._embedCodeModel;
};

/**
 * Returns the existing instance of WebfontProjectStylesModel.
 *
 * @returns {Object}
 */
AddFontsToWebProjectModel.prototype.getWebfontProjectStylesModel = function () {
  return this._webfontProjectStylesModel;
};

/**
 * Returns the number of fonts added to the project.
 *
 * @return {Number}
 */
AddFontsToWebProjectModel.prototype.getAddedFontsCount = function () {
  return this._addedFontsCount;
};

/**
 * Returns the name of the project to which fonts were added.
 *
 * @return {String}
 */
AddFontsToWebProjectModel.prototype.getAddedToProjectName = function () {
  return this._addedToProjectName;
};

/**
 * Returns the project editor url for the project to which fonts were added.
 *
 * @return {String}
 */
AddFontsToWebProjectModel.prototype.getAddedToProjectEditorLink = function () {
  return this._addedToProjectEditorLink;
};

/**
 * Return a project id to project info mapping for the given projects array.
 *
 * @private
 * @params {Array} projects
 * @return {Object}
 */
AddFontsToWebProjectModel.prototype._getProjectById = function (projects) {
  var projectById = {};
  projects.forEach(function (project) {
    projectById[project.id] = project;
  });
  return projectById;
};

/**
 * Assigns _projectById and _isFamilyCJK by resolving the given promise.
 *
 * @private
 * @params {Promise} deferredFamilyAndProjectsInfo
 */
AddFontsToWebProjectModel.prototype._populateFamilyAndProjectsInfo = function (deferredFamilyAndProjectsInfo) {
  var self = this;
  deferredFamilyAndProjectsInfo.then(function (familyAndProjectsInfo) {
    self._projectById = self._getProjectById(familyAndProjectsInfo.projects);
    self._isFamilyCJK = familyAndProjectsInfo.is_family_CJK;
  });
};

/**
 * Add selected variations to an existing web project and return data to be used for info display page.
 *
 * @private
 * @param {Array} selectedVariations
 * @param {String} projectId
 * @return {Promise}
 */
AddFontsToWebProjectModel.prototype._addSelectedToExistingProject = function (selectedVariations, projectId) {
  var payload = {
    id: projectId,
    families: selectedVariations
  };
  return this.$http.post('/kits/update', payload).then(function(response){
    return response.data;
  });
};

/**
 * Add selected variations to a new web project and return data to be used for info display page.
 *
 * @private
 * @param {Array} selectedVariations
 * @param {String} projectName
 * @return {Promise}
 */
AddFontsToWebProjectModel.prototype._addSelectedToNewProject = function (selectedVariations, projectName) {
  var payload = {
    kit: {
      name: projectName
    },
    families: selectedVariations
  };
  return this.$http.post('/kits', payload).then(function(response){
    return response.data;
  });
};

/**
 * Handle a successful save event.
 *
 * @private
 * @param {Object} projectUsageInfo
 */
AddFontsToWebProjectModel.prototype._handleSaveSuccess = function(projectUsageInfo) {
  this._embedCodeModel =
    new EmbedCodeModel(projectUsageInfo.embed_code.is_dynamic, projectUsageInfo.embed_code.project_embed_details);

  this._webfontProjectStylesModel = new WebfontProjectStylesModel(projectUsageInfo.styles);
  this._addedFontsCount = projectUsageInfo.added_fonts_count;
  this._addedToProjectName = projectUsageInfo.added_to_project_info.name;
  this._addedToProjectEditorLink = projectUsageInfo.added_to_project_info.editor_link;
  this._saved = true;
};

module.exports = AddFontsToWebProjectModel;
