var _ = require('underscore');

const NotificationEvent = require('@adobe-fonts/site/events/NotificationEvent.js');

/**
 * Angular controller for notification messages.
 * @ngInject
 */
function NotificationController($scope, $window, DataService, FirstMileService, NewrelicService, NotificationService, UserPrefsService) {
  this.DataService = DataService;
  this.FirstMileService = FirstMileService;
  this.NewrelicService = NewrelicService;
  this.NotificationService = NotificationService;
  this.UserPrefsService = UserPrefsService;
  this.$scope = $scope;
  this.$window = $window;

  this.retiredFoundries = '';
  this.retiredFamilies = '';

  this._preloadData();
  this._addEventListeners();
}

module.exports = NotificationController;

/**
 * Are there messages to display?
 * @returns {Boolean}
 */
NotificationController.prototype.hasMessages = function() {
  return this.NotificationService.getMessages().length > 0;
};

/**
 * Returns all messages or just messages of the type passed in.
 *
 * @param {Object} message
 */
NotificationController.prototype.handleNeutralTopNotificationClose = function(message) {
  if (message.scriptIdentifier === 'standalone_subscription') {
    this.UserPrefsService.updatePrefs({ hideStandaloneSubscriptionNotification: true });
  }
  if (message.scriptIdentifier === 'retired_family_banner') {
    this.UserPrefsService.updatePrefs({ hideFamilyRetirementNotification: this.retiredFamilies });
  }
  if (message.scriptIdentifier === 'retired_foundry_banner') {
    this.UserPrefsService.updatePrefs({ hideFoundryRetirementNotification: this.retiredFoundries });
  }
  this.NotificationService.remove(message);
};

/**
 * Handles a close click on the feedback notification
 * @returns {void}
 */
NotificationController.prototype.handleFeedbackClose = function(message) {
  var prefs = {};
  this.NotificationService.remove(message);
  if (!_.isEmpty(prefs)) {
    this.UserPrefsService.updatePrefs(prefs);
  }
};

/**
 * Handles a open CCD app button on the family activation toast
 */
NotificationController.prototype.handleOpenCCDButton = function(queryParams) {
  var self = this;
  this.NewrelicService.addPageAction('typekit.click.use-model-font-activation-open-app', { source: 'Success Toast' });
  self.FirstMileService.openCreativeCloudDesktop(queryParams);
};

/**
 * Get the latest notices from the notification service.
 * @returns {[{}]}
 */
NotificationController.prototype.latestNotices = function() {
  return this.NotificationService.getMessages(this.NotificationService.TYPES.NOTICE);
};

/**
 * Get the latest errors from the notification service.
 * @returns {[{}]}
 */
NotificationController.prototype.latestErrors = function() {
  return this.NotificationService.getMessages(this.NotificationService.TYPES.ERROR);
};

/**
 * Handles the action link click for a notification
 * @returns {void}
 */
NotificationController.prototype.handleActionClick = function(message) {
  if (message.actionLink === 'open ccd') {
    this.handleOpenCCDButton(message.queryParams);
  } else {
    this.$window.location = message.actionLink;
  }
};

/**
 * Handles a close notification click
 * @returns {void}
 */
NotificationController.prototype.handleCloseClick = function(message) {
  this.NotificationService.remove(message);
};

/**
 * Gets all messages
 * @returns {Array}
 */
NotificationController.prototype.getMessages = function() {
  return this.NotificationService.getMessages();
};

NotificationController.prototype.getToastClass = function(message) {
  if (message.type === 'neutral') {
    return 'spectrum-Toast--neutral';
  }

  if (message.type === 'font_game_banner') {
    return 'font-game-toast';
  }

  if (message.type === 'retired_family_banner' || message.type === 'retired_foundry_banner') {
    return 'retirement-banner-toast';
  }

  if (message.type === 'site_maintenance_banner') {
    return 'site-maintenance-toast';
  }
};

NotificationController.prototype._addEventListeners = function() {
  this.$window.addEventListener(NotificationEvent.EVENT_NAME, (event) => {
    this.NotificationService[event.detail.type](event.detail.message, event.detail.options || {});
    this.$scope.$apply();
  });
};

/**
 * Loads in user preferences and any messages that were set as part of script tags.
 */
NotificationController.prototype._preloadData = function() {
  var self = this;

  self.DataService.get('/notification/error').then(function(error) {
    if (_.isString(error)) {
      error = {message: error};
    }

    self.NotificationService.error(error.message, error.options);
  });

  self.DataService.get('/notification/notice').then(function(notice) {
    if (_.isString(notice)) {
      notice = {message: notice};
    }

    self.NotificationService.notice(notice.message, notice.options);
  });

  self.DataService.get('/notification/info').then(function(notice) {
    if (_.isString(notice)) {
      notice = {message: notice};
    }

    self.NotificationService.notice(notice.message, notice.options);
  });

  self.DataService.get('/notification/success').then(function(success) {
    if (_.isString(success)) {
      success = {message: success};
    }

    self.NotificationService.success(success.message, success.options);
  });

  self.DataService.get('/notification/warn').then(function(warn) {
    if (_.isString(warn)) {
      warn = {message: warn};
    }

    self.NotificationService.warn(warn.message, warn.options);
  });

  self.DataService.get('/notification/first_mile').then(function(firstMile) {
    if (firstMile === 'activate') {
      return self.FirstMileService.showFontActivationDialog();
    }

    if (firstMile == 'um_activate') {
      return self.FirstMileService.showUseModelFontActivationDialog();
    }
  });

  self.DataService.get('/notification/site_maintenance_banner').then(function(notice) {
    if (_.isString(notice)) {
      notice = {message: notice};
    }

    self.NotificationService.site_maintenance_banner(notice.message, notice.options);
  });

  self.DataService.get('/notification/font_game_banner').then(function(notice) {
    if (_.isString(notice)) {
      notice = {message: notice};
    }

    self.NotificationService.font_game_banner(notice.message, notice.options);
  });

  self.DataService.get('/notification/retired_family_banner').then(function(notice) {
    if (_.isString(notice)) {
      notice = {message: notice};
    }

    self.retiredFamilies = _.map(notice.options.retirementMessages, (retired) => retired.slug).join(', ');

    self.NotificationService.retired_family_banner(notice.message, notice.options);
  });

  self.DataService.get('/notification/retired_foundry_banner').then(function(notice) {
    if (_.isString(notice)) {
      notice = {message: notice};
    }

    self.retiredFoundries = _.map(notice.options.retirementMessages, (retired) => retired.slug).join(', ');

    self.NotificationService.retired_foundry_banner(notice.message, notice.options);
  });

  self.DataService.get('/notification/standalone_subscription').then(function(notice) {
    if (_.isString(notice)) {
      notice = {message: notice};
    }

    self.NotificationService.neutral(notice.message, notice.options);
  });
};

