/**
 * A function that takes an Object as input
 * and sets the details for styles component
 *
 * @param {Object} stylesModelData
 * @ngInject
 */
function WebfontProjectStylesModel(stylesModelData) {
  var self = this;

  self.fontVariationsDetails = stylesModelData;
  self._preloadData();
}

WebfontProjectStylesModel.prototype._preloadData = function() {
  var self = this;
  self.fontVariationsDetails.forEach(function(fontVariation) {
    self.setCSSFontStackDisplay(fontVariation);
    self.setStyleDisplay(fontVariation);
    self.setWeightDisplay(fontVariation);
    self.setCompleteFontVariationStyle(fontVariation);
  });
};

/**
 * TODO : Logic might change later
 * Sets the fontFamilyDisplay and cssFontStackDisplay property for each FontVariation
 * @param {Object} fontVariation
 */
WebfontProjectStylesModel.prototype.setCSSFontStackDisplay = function(fontVariation) {
  var cssFontStack = fontVariation.family.css_font_stack.replace(/["]/g, '');
  fontVariation.fontFamilyDisplay = cssFontStack.substr(0, cssFontStack.indexOf(',') + 1) || cssFontStack;
  fontVariation.cssFontStackDisplay = cssFontStack.replace(fontVariation.fontFamilyDisplay, '') + ';';
};

/**
 * Sets the styleDisplay property for each FontVariation
 * @param {Object} fontVariation
 */
WebfontProjectStylesModel.prototype.setStyleDisplay = function(fontVariation) {
  fontVariation.styleDisplay = fontVariation.style + ';';
};

/**
 * Sets the weightDisplay property for each FontVariation
 * @param {Object} fontVariation
 */
WebfontProjectStylesModel.prototype.setWeightDisplay = function(fontVariation) {
  fontVariation.weightDisplay = fontVariation.font.web.weight + ';';
};

/**
 * Sets the completeFontVariationStyle property for each FontVariation
 * @param {Object} fontVariation
 */
WebfontProjectStylesModel.prototype.setCompleteFontVariationStyle = function(fontVariation) {
  fontVariation.completeFontVariationStyle = 'font-family: ' +
    fontVariation.fontFamilyDisplay + fontVariation.cssFontStackDisplay +
    '\nfont-weight: ' + fontVariation.weightDisplay + '\nfont-style: ' +
    fontVariation.styleDisplay;
};

WebfontProjectStylesModel.prototype.getFontVariations = function() {
  return this.fontVariationsDetails;
};

module.exports = WebfontProjectStylesModel;
