const angular = require('angular');

/**
 * Return the scope from the top level Angular app.
 */
export function getScope() {
  try {
    return angular.element(document.body).scope();
  } catch (e) {
    // Noop
  }
}

/**
 * Use the top level Angular app to resolve an Angular service.
 *
 * This method relies on the fact that an Angular app has been initialized on
 * the page. The intent is to be able to use our existing Angular service code
 * (until it can be migrated) without having to have the new code connected to
 * an Angular controller.
 *
 * If the Angular app can't be found or the service can't be found, this method
 * won't return anything.
 */
export function getService(serviceName) {
  // The `get` method will allow to resolve the Angular service.
  //
  // If Angular can't find the service, it will throw an error.
  // Catch the error so we can handle this gracefully.
  try {
    return angular.element(document.body).injector().get(serviceName);
  } catch (e) {
    // Noop
  }
}

/**
 * Call $apply on the top level scope.
 */
export function scopeApply(func) {
  const scope = getScope();
  if (!scope) {
    return;
  }

  try {
    scope.$apply(func);
  } catch (e) {
    // Noop
  }
}
