export function trapFocus() {
  const focusTrappableElements = getFocusTrappableElements();
  if (focusTrappableElements) {
    focusTrappableElements.forEach(el => {
      el.inert = true;
    });
  }
}

export function releaseFocus() {
  const focusTrappableElements = getFocusTrappableElements();
  if (focusTrappableElements) {
    focusTrappableElements.forEach((el) => {
      el.inert = false;
    });
  }
}

function getFocusTrappableElements() {
  return document.querySelectorAll('.af-focus-trappable');
}
