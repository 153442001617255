/**
 * Make a fetch DELETE request that includes our CSRF token.
 * It can't be named `delete` because that's a reserved word.
 */
export function del(url, options) {
  return secureFetch(url, {
    method: 'DELETE',
    ...options
  });
}

/**
 * Make a fetch POST request that includes our CSRF token.
 */
export function post(url, options) {
  return secureFetch(url, {
    method: 'POST',
    ...options
  });
}

/**
 * Make a fetch PUT request that includes our CSRF token.
 */
export function put(url, options) {
  return secureFetch(url, {
    method: 'PUT',
    ...options
  });
}

/**
 * Make a fetch GET request that includes our CSRF token.
 */
export function get(url, options) {
  return secureFetch(url, {
    method: 'GET',
    ...options
  });
}

function secureFetch(url, options) {
  const tokenEl = document.querySelector('meta[name=csrf-token]');

  const { headers, ...otherOptions } = options;
  const secureOptions = {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': tokenEl?.getAttribute('content'),
      'X-Requested-With': 'XMLHttpRequest',

      ...headers
    },
    ...otherOptions
  };

  return fetch(url, secureOptions);
}
