// Add any post login action types here
const POST_LOGIN_ACTION_TYPE = {
  // A user tried to add a family to web project without being logged in
  ADD_TO_WEB_PROJECT: 'add_to_web_project',
  // A user tried to open the "Save to Library" menu (which contains a list of the user's libraries) without being logged in
  OPEN_LIBRARY_MENU: 'open_library_menu',
  // A user tried to copy a library without being logged in
  COPY_LIBRARY: 'copy_library',
  // A user tried to follow a library without being logged in
  FOLLOW_LIBRARY: 'follow_library'
};

export default POST_LOGIN_ACTION_TYPE;
